import React from "react";
import mock01 from '../assets/images/projects/1.jpg';
import mock02 from '../assets/images/projects/2.png';
import mock03 from '../assets/images/projects/3.png';
import mock04 from '../assets/images/projects/4.png';
import mock05 from '../assets/images/projects/5.png';
import mock06 from '../assets/images/mock_05.png';
import mock07 from '../assets/images/projects/7.png';
import mock08 from '../assets/images/projects/8.jpg';
import '../assets/styles/Project.scss';

function Project() {
    return(
    <div className="projects-container" id="projects">
        <h1>My Proud Projects</h1>
        <div className="projects-grid">
            <div className="project">
                <a href="https://magdalenastudios.com/" target="_blank" rel="noreferrer"><img src={mock02} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://magdalenastudios.com/" target="_blank" rel="noreferrer"><h2>Davidson Homes LLC</h2></a>
                <p>Developed a home construction and real estate development company web application using Next.js, React.js, TailwindCSS, and TypeScript.</p>
            </div>
            <div className="project">
                <a href="https://magdalenastudios.com/" target="_blank" rel="noreferrer"><img src={mock01} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://magdalenastudios.com/" target="_blank" rel="noreferrer"><h2>Magdalena Studios</h2></a>
                <p>Developed a photography studio website specializing in fine art wedding, portrait, and lifestyle photography using Next.js and TypeScript.</p>
            </div>
            <div className="project">
                <a href="https://www.latenightrecordpool.com" target="_blank" rel="noreferrer"><img src={mock03} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://www.latenightrecordpool.com" target="_blank" rel="noreferrer"><h2>Late Night Record Pool</h2></a>
                <p>Developed Angular based web application for DJs and music professionals including exclusive remixes, edits, and DJ-friendly versions.</p>
            </div>
            <div className="project">
                <a href="https://chainedandable.com/" target="_blank" rel="noreferrer"><img src={mock04} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://chainedandable.com/" target="_blank" rel="noreferrer"><h2>Chained & Able</h2></a>
                <p>Worked as a frontend developer for jewelry brand ecommerce project using React.js, Redux, Bootstrap, and TypeScript.</p>
            </div>
            <div className="project">
                <a href="https://slumber.fm/" target="_blank" rel="noreferrer"><img src={mock05} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://slumber.fm/" target="_blank" rel="noreferrer"><h2>Slumber iOS/Android Apps: React Native</h2></a>
                <p>Built using React native, Apollo GrpahQL, and Redux. This is an improve their sleep through a collection of sleep-inducing stories, meditations, and sounds.</p>
            </div>
            <div className="project">
                <a href="https://www.byuh.edu/covid-19-case-management" target="_blank" rel="noreferrer"><img src={mock06} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://www.byuh.edu/covid-19-case-management" target="_blank" rel="noreferrer"><h2>COVID-19 Case Management</h2></a>
                <p>Built official charts for COVID/vaccination tracking for an educational institution using JavaScript and the Google Sheets API v4. The dashboard served the university's leadership in their decision-making processes.</p>
            </div>
            <div className="project">
                <a href="https://www.myhotcars.com/" target="_blank" rel="noreferrer"><img src={mock07} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://www.myhotcars.com/" target="_blank" rel="noreferrer"><h2>My Hot Cars</h2></a>
                <p>Car dealer application specializing in the sale of classic, vintage, and specialty vehicles. It was built with Vue.js, Node.js, and Bootstrap</p>
            </div>
            <div className="project">
                <a href="https://stanleycc.org/" target="_blank" rel="noreferrer"><img src={mock08} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://stanleycc.org/" target="_blank" rel="noreferrer"><h2>Stanley CC</h2></a>
                <p>Built WordPress website for the Stanley CC using Divi theme builder.</p>
            </div>
            

        </div>
    </div>
    );
}

export default Project;