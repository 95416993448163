import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhotoImage from "../assets/images/photo.png";
import '../assets/styles/Main.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

function Main() {

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
          <img src={PhotoImage} alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">
            <a href="https://github.com/smart-tech-guru" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/bai-yu-2aa462307" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
          <h1>Brian Yu (Bai Yu)</h1>
          <p>Senior Full Stack Developer</p>
          <br />
          <p className="address">
            <FontAwesomeIcon icon={faLocation} /> Warsaw, Poland
          </p>
          <p className="email">
            <a href="mailto:baiyu29.tech@gmail.com" ><FontAwesomeIcon icon={faEnvelope} /> baiyu29.tech@gmail.com</a>
          </p>

          <div className="mobile_social_icons">
            <a href="https://github.com/smart-tech-guru" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/bai-yu-2aa462307/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;