import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faPython, faNode, faPhp, faAngular, faVuejs, faAws, faGithub } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

const labelsFirst = [
    "React",
    "Angular",
    "Vue3",
    "Next.js",
    "Svelte.js",
    "React Native",
    "TypeScript",
    "EcmaScript",
    "Tailwind CSS",
    "Bootstrap",
    "MUI",
    "Nuxt.js",
    "jQuery",
    "HTML5",
    "CSS3",
    "Responsive design",
    "SCSS/LESS",
    "Jest",
    "Vitest"
];

const labelsSecond = [
    "Docker",
    "Kubernetes",
    "AWS",
    "Git",
    "Azure",
    "GCP",
    "CircleCI",
    "CI/CD",
    "GitHub Actions",
    "Cypress",
    "Playwright"
];

const labelsThird = [
    "Node.js",
    "PHP",
    "Python",
    "Express.js",
    "Nest.js",
    "Django",
    "Laravel",
    "FastAPI",
    "Flask",
    "TypeORM",
    "Sequelize",
    "Prisma",
    "GraphQL",
    "MySQL",
    "PostgreSQL",
    "MongoDB",
    "AWS Lambdas",
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <br/>
            <div className="skills-grid">
                <div className="skill">
                    <div className="icons">
                        <FontAwesomeIcon icon={faReact} size="3x"/>
                        <FontAwesomeIcon icon={faAngular} size="3x"/>
                        <FontAwesomeIcon icon={faVuejs} size="3x"/>
                        <FontAwesomeIcon icon={faMobileAlt} size="3x"/>
                    </div>
                    <h3>Frontend Development</h3>
                    <p>8 years of expertise in React, Vue, Angular, Svelte.js, and React Native. Skilled in building dynamic, responsive web and mobile applications, I have a deep understanding of component-based architectures, reactive data binding, and state management. Proficient in delivering scalable solutions using modern JavaScript frameworks, I am adept at optimizing performance, ensuring cross-browser compatibility, and implementing best practices in UI/UX design.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsFirst.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <div className="icons">
                        <FontAwesomeIcon icon={faNode} size="3x"/>
                        <FontAwesomeIcon icon={faPhp} size="3x"/>
                        <FontAwesomeIcon icon={faPython} size="3x"/>
                    </div>
                    <h3>Backend Development</h3>
                    <p>7 years of expertise in REST API development, database design, and system architecture design. Proficient in building scalable and secure backend systems, I excel in creating well-structured APIs, designing efficient database schemas, and implementing robust system architectures. Skilled in server-side programming, database optimization, and cloud integration, I am adept at delivering high-performance solutions. With a deep understanding of security best practices and microservices, I am focused on delivering reliable backend services that meet complex business requirements.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsThird.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <div className="icons">
                        <FontAwesomeIcon icon={faDocker} size="3x"/>
                        <FontAwesomeIcon icon={faAws} size="3x"/>
                        <FontAwesomeIcon icon={faGithub} size="3x"/>
                    </div>
                    <h3>DevOps & Automation</h3>
                    <p>Strong background in DevOps using AWS, Docker, GitHub, and Kubernetes. Proficient in both frontend and backend development, I excel in building scalable web applications and designing robust architectures. Skilled in cloud services, containerization, and orchestration, I implement CI/CD pipelines and automate deployment processes to ensure efficient and reliable releases.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;